import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TempHome from './pages/TempHome';

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
       <Route path="/" element={<Home />} />
     {/*  <Route path="/" element={<TempHome />} /> */}
      </Routes>
     <Footer/>
    </Router>
  );
}

export default App;