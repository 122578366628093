import React, {useState} from "react";
import logo1 from "../media/VanBelle Logo Light.png";
const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };


  return (
    <nav className="bg-white border-gray-200 dark:bg-vanbellebrown">
    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="https://vanbelle-lb.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={logo1} className="w-auto h-8 lg:h-10" alt="Van Belle" />
      </a>
      <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
        <button
          type="button"
          className="text-white bg-vanbellebrown hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Contact Us
        </button>
        <button
          type="button"
          onClick={toggleNavbar}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-expanded={isOpen ? "true" : "false"}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} md:block md:order-1 md:w-auto`} id="navbar-cta">
        <ul className="flex flex-col font-medium p-4 md:p-0 mt-4  bg-vanbellebrown  rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0   ">
          <li>
            <a href="#main" className="block py-2 px-3 md:p-0 bg-vanbellebrown text-white rounded  md:text-blue-700 md:dark:text-blue-500" aria-current="page">
              Home
            </a>
          </li>
          <li>
            <a href="#latest" className="block py-2 px-3 md:p-0 bg-vanbellebrown text-gray-900 rounded  md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
              Our Latest
            </a>
          </li>
   
          <li>
            <a href="#about" className="block py-2 px-3 md:p-0 bg-vanbellebrown text-gray-900 rounded  md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
              About
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
};
export default Navbar;
