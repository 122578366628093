import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cocoa from "../media/CocoaBean.png";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer className="bg-vanbellebrown text-white py-8">
    <div className="max-w-screen-xl mx-auto px-4">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="mb-4 md:mb-0">
          <h3 className="text-xl mb-2">Contact Us</h3>
          <p>+961 06 431 985</p>
          <p><a href="https://wa.me/96176919011">+961 76 919 011</a></p>
          <p>Email: info@vanbelle-lb.com</p>
          <p>Hazem El Jisir Street, Facing St Familie School</p>
        </div><img className="h-24 w-28 my-4" src={cocoa}/>
        <div>
          <div className="flex space-x-4">
          <div className="text-center mt-4 pb-8">
          <p className="text-lg">
            <a href="https://wa.me/96176919011" target="_blank">
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="h-10 w-10 text-lime-400 socialicons"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.facebook.com/Vanbelle.lb?mibextid=LQQJ4d"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="h-10 w-10 text-sky-500 socialicons"
              />
            </a>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/vanbelle.lb/" target="_blank">
              <FontAwesomeIcon
                icon={faInstagram}
                className="h-10 w-10 text-orange-400 socialicons"
              />
            </a>
          </p>
        </div>
          </div>
        </div>
      </div>
      <hr className="my-8 border-gray-500" />
      <p className="text-center">© {new Date().getFullYear()} Chocolatier Van Belle. All rights reserved.</p>
    </div>
  </footer>
  );
};

export default Footer;