import React from "react";
import photo from "../media/goldenbox.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Contact = () => {
  return (
    <section
      id="about"
      className="bg-center bg-no-repeat bg-cover bg-gray-700 bg-blend-multiply h-2/5 flex items-center justify-center"
      style={{ backgroundImage: `url(${photo})` }}
    >
      <div className="px-4 mx-auto max-w-screen-xl text-center lg:py-6">
        <h1 className="py-4 text-4xl tracking-tight leading-none text-white md:text-4xl lg:text-6xl  ">
          Find Us
        </h1>
        <div className="text-center mt-4 pb-8">
          <p className="text-lg">
            <a href="https://wa.me/96176919011" target="_blank">
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="h-14 w-14 text-lime-400 socialicons"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.facebook.com/Vanbelle.lb?mibextid=LQQJ4d"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="h-14 w-14 text-sky-500 socialicons"
              />
            </a>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/vanbelle.lb/" target="_blank">
              <FontAwesomeIcon
                icon={faInstagram}
                className="h-14 w-14 text-orange-400 socialicons"
              />
            </a>
          </p>
        </div>
        <iframe
          className="pb-6 mapframe"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13162.360509906988!2d35.8317263!3d34.4371649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1521f6ab8b9426b5%3A0x6f20da7b0cf352a5!2sChocolatier%20Van%20Belle!5e0!3m2!1sen!2slb!4v1709489804131!5m2!1sen!2slb"
          width="800"
          height="550"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="flex flex-col space-y-4 py-6 sm:flex-row sm:justify-center sm:space-y-0">
          <a
            href="https://wa.me/96176919011"
            target="_blank"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
          >
            Contact us
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
          <a
            href="https://maps.app.goo.gl/7PQF3VH4TTaEFaKP6"
            target="_blank"
            className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
          >
            Visit Us
          </a>
        </div>
      </div>
      <div>
        
      </div>
    </section>
  );
};
export default Contact;
