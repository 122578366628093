import React from "react";
import photo from "../media/chocolatebars.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Services = () => {
  const urls = [
    "https://www.instagram.com/p/C3rqeG5If5R/embed",
    "https://www.instagram.com/p/C37PZ9oIy6s/embed",
    "https://www.instagram.com/p/C3DSfosoV71/embed",
  ];
  return (
    <section
      id="latest"
      className="bg-center bg-no-repeat bg-cover py-10 bg-gray-700 bg-blend-multiply h-fit  items-center justify-center"
      style={{ backgroundImage: `url(${photo})` }}
    >
      {" "}
      <div className="mx-auto max-w-screen-xl text-center ">
        <h1 className="py-4 text-4xl tracking-tight leading-none text-white md:text-4xl lg:text-6xl  ">
          Our Latest
        </h1>
      </div>
      <div className="flex flex-wrap p-5 justify-center">
        {urls.map((url, index) => (
          <iframe
            key={index}
            src={url}
            title={`Instagram Post ${index + 1}`}
            width="320"
            height="500"
            frameborder="0"
            scrolling="no"
            allowtransparency="true"
            className="m-4"
          ></iframe>
        ))}
      </div>
    </section>
  );
};
export default Services;
