import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import Contact from "./Contact";

const Home = () => {

  return (
   <div>
 <Hero />
<Services />
<Contact />

    </div>
  );
};
export default Home;