import React from "react";
import photo from "../media/cacao.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Hero = () => {
  return (
    <section  id="main"
  className="bg-center bg-no-repeat bg-cover bg-gray-700 bg-blend-multiply  flex items-center justify-center"
  style={{ backgroundImage: `url(${photo})` }}
>
      <div className="px-4 mx-auto max-w-screen-xl text-center lg:py-56">
      <h1 className="mb-2 pt-9 text-8xl tracking-tight leading-none text-vanbellelight md:text-8xl lg:text-9xl font-bickham-script-pro shadow-lg">
  Van Belle
</h1>

        <p className="mb-8 text-4xl font-light text-vanbellelight lg:text-4xl sm:px-16 lg:px-20 font-bickham-script-pro">
          The Real Chocolate From Belgium
        </p>
        <div className="lg:flex">
        <p className="mb-8 text-base font-light text-gray-300 lg:text-xl sm:px-16 lg:px-20 ">
          Van Belle takes special care with each of its creations. We make no
          compromises in our recipes; they are unique and meet the standards of
          quality chocolate and creativity upheld in Belgium. Furthermore, we
          select only the best ingredients in terms of origin, guaranteeing you
          an exceptional product.
        </p>
        <p className="mb-8 text-base font-light text-gray-300 lg:text-xl sm:px-16 lg:px-20 ">
          Van Belle expertise is based on over 20 years of tradition and this
          dynamic and innovative company enjoys an international reputation for
          its wide range of high quality products. These, along with its image,
          are regularly reworked and updated. It is this shrewd blend of
          tradition, modernity and quality that contributes to the brand’s
          success.
        </p></div>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <a
            href="https://wa.me/96176919011"
            target="_blank"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
          >
            Contact us
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
          <a
            href="https://maps.app.goo.gl/7PQF3VH4TTaEFaKP6"
            target="_blank"
            className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
          >
            Visit Us
          </a>
        </div>
        <div className="text-center mt-4">
          <p className="text-lg">
            <a href="https://wa.me/96176919011" target="_blank">
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="h-12 w-12 text-lime-400 socialicons"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.facebook.com/Vanbelle.lb?mibextid=LQQJ4d"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="h-12 w-12 text-sky-500 socialicons"
              />
            </a>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/vanbelle.lb/" target="_blank">
              <FontAwesomeIcon
                icon={faInstagram}
                className="h-12 w-12 text-orange-400 socialicons"
              />
            </a>
          </p>
        </div>
        <p className="mb-8 mt-8 font-bold text-lg  text-gray-300 lg:text-lg sm:px-16 lg:px-20 ">
          Website Under Construction...
        </p>
      </div>
    </section>
  );
};
export default Hero;
